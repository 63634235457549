export default class Links {
    static readonly WEBSITE_NAME = "unclothy.com";
    static readonly WEBSITE = "https://" + Links.WEBSITE_NAME;
    static readonly PAYMENT_REDIRECT = "/redirect?link=";
    static readonly CONTACT_EMAIL = "contact@unclothy.com";
    static readonly BUSINESS_EMAIL = "business@unclothy.com";
    static readonly CONTACT_EMAIL_LINK = "mailto:" + Links.CONTACT_EMAIL;
    static readonly BUSINESS_EMAIL_LINK = "mailto:" + Links.BUSINESS_EMAIL;
    static readonly TELEGRAM_BOT = "https://t.me/unclothy1_bot";
    static readonly TWITTER_ACCOUNT = "https://x.com/unclothy";
    static readonly DOCUMENTATION = "https://unclothy.readme.io";
}
