"use client";

import { ReactNode, useEffect, useState } from "react";
import { ThemeProvider as Provider } from "next-themes";

export function ThemeProvider({ children }: { children: ReactNode }) {
    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => setMounted(true), []);

    if (!mounted) return children;

    return (
        <Provider
            attribute="class"
            defaultTheme="system"
            enableSystem
        >
            {children}
        </Provider>
    );
}
